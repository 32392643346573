<template>
  <v-fade-transition>
    <v-form @submit.prevent="codeExpired ? resendCode : submitCode">
      <v-slide-x-transition mode="out-in">
        <div v-if="tooManyRequests">
          <v-card-text class="multi-factor__container py-0">
            <v-card-title class="pt-0 justify-center">
              Too many failed attempts.
            </v-card-title>
            <v-card-subtitle> Please try again later.</v-card-subtitle>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div>
              <v-btn color="primary" @click="backToLogin"> Back </v-btn>
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
        </div>
        <div v-else-if="codeExpired" key="codeExpired">
          <v-card-text class="multi-factor__container py-0 pb-4">
            <v-card-title class="pt-0 justify-center">
              Sorry! Too many failed attempts.
            </v-card-title>
            <v-card-subtitle>
              {{ `Please re-send a code to ${phoneNumberHint} and try again.` }}
            </v-card-subtitle>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div>
              <v-btn
                :disabled="!verificationId || submitting"
                color="primary"
                @click="resendCode"
                type="submit"
              >
                Re-send code
              </v-btn>
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
        </div>

        <div v-else key="codeNotExpired">
          <v-card-text class="multi-factor__container py-0">
            <v-card-title class="pt-0">
              Your account has two-factor authentication enabled.
            </v-card-title>
            <v-card-subtitle>
              {{ `Please enter the code sent to ${phoneNumberHint}` }}
            </v-card-subtitle>
            <div class="d-flex justify-center">
              <v-text-field
                v-model="verificationCode"
                :error-messages="codeErrors"
                class="verification-code__input mt-5"
                outlined
                :disabled="submitting"
                @input="codeErrors = []"
              />
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <div>
              <v-btn
                :disabled="!verificationCode || !verificationId || submitting"
                color="primary"
                @click="submitCode"
                type="submit"
              >
                Submit
              </v-btn>
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
        </div>
      </v-slide-x-transition>
      <div id="recaptcha-container" />
    </v-form>
  </v-fade-transition>
</template>
<style lang="scss">
.multi-factor__container {
  .v-card__title {
    word-break: break-word !important;
    text-align: center;
  }
  .v-card__subtitle {
    word-break: break-word !important;
    text-align: center;
  }
  .verification-code__input {
    max-width: 200px;
    .v-text-field__slot {
      input {
        text-align: center;
        font-size: 2em !important;
      }
    }
  }
}
</style>
<script>
import {
  getAuth,
  // grecaptcha,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from 'firebase/auth';

export default {
  name: 'MultiFactorAuth',
  data() {
    return {
      checkRecaptchaInterval: null,
      codeErrors: [],
      codeExpired: false,
      phoneAuthProvider: null,
      recaptchaVerifier: null,
      submitting: false,
      tooManyRequests: false,
      verificationId: null,
      verificationCode: null,
    };
  },
  props: {
    resolver: {
      type: Object,
      required: true,
    },
  },
  computed: {
    phoneNumberHint() {
      return this.resolver.hints[0].phoneNumber;
    },
  },
  methods: {
    backToLogin() {
      this.reset();
      this.$emit('back');
    },
    async renderRecaptcha() {
      const auth = getAuth();

      this.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
        auth
      );
    },
    async submitCode() {
      this.submitting = true;
      try {
        const phoneAuthCredential = PhoneAuthProvider.credential(
          this.verificationId,
          this.verificationCode
        );
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
        const userCredential = await this.resolver.resolveSignIn(multiFactorAssertion);
        const { accessToken: token } = userCredential?.user || {};

        if (token) {
          this.$emit('success', token);
          this.codeErrors = [];
        } else {
          throw new Error({ code: 'tokenFailure' });
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(error) {
      this.submitting = false;
      this.verificationCode = null;

      if (error.code === 'auth/invalid-verification-code') {
        this.codeErrors.push('Incorrect code.');
      } else if (error.code === 'auth/code-expired') {
        this.onCodeExpired();
      } else if (error.code === 'auth/too-many-requests') {
        this.onTooManyRequests();
      } else {
        this.codeErrors.push('Error. Please try again.');
      }
    },
    lookForRecaptcha() {
      const isPuzzleDisplayed = document.querySelectorAll(
        'iframe[title*="recaptcha challenge expires in two minutes"]'
      )[0];
      const recaptchaWindow = isPuzzleDisplayed?.parentElement?.previousElementSibling;
      if (recaptchaWindow) {
        recaptchaWindow.style.setProperty('pointer-events', 'none');
      }
    },
    onCodeExpired() {
      this.codeExpired = true;
    },
    onTooManyRequests() {
      this.tooManyRequests = true;
    },
    async resetRecaptcha() {
      const widgetId = await this.recaptchaVerifier.render();
      this.recaptchaVerifier.recaptcha.reset(widgetId);
    },
    async resendCode() {
      this.submitting = true;
      try {
        await this.resetRecaptcha();
        await this.verifyPhoneNumber();
        this.codeExpired = false;
      } catch (err) {
        this.handleError(err);
      }

      this.submitting = false;
    },
    reset() {
      this.codeErrors = [];
      this.codeExpired = false;
      this.tooManyRequests = false;
    },
    async sendCode() {
      this.submitting = true;
      try {
        await this.renderRecaptcha();
        await this.verifyPhoneNumber();
      } catch (err) {
        this.handleError(err);
      }
      this.submitting = false;
    },
    async verifyPhoneNumber() {
      const auth = getAuth();
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const { recaptchaVerifier } = this;
      const [multiFactorHint] = this.resolver.hints;
      const { session } = this.resolver;
      if (!multiFactorHint || !session || !phoneAuthProvider || !recaptchaVerifier) return null;

      const phoneInfoOptions = {
        multiFactorHint,
        session,
      };

      return phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
        .then((verificationId) => {
          this.verificationId = verificationId;
        });
    },
  },
  async mounted() {
    this.sendCode();
    this.checkRecaptchaInterval = setInterval(() => {
      this.lookForRecaptcha();
    }, 100);
  },
  beforeDestroy() {
    clearInterval(this.checkRecaptchaInterval);
  },
};
</script>
